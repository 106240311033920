<template>
  <div class="loading">
    <img src="@/assets/img/loading.svg" />
  </div>
</template>

<script>
export default {
  name: "admin-login",
  data() {
    return {
      fullUrl: "",
    };
  },
  methods: {
    login(user_id) {
      let data = {
        user_id: user_id,
      };
      
      this.$store.dispatch("authRequestWhitUser", data);
    },
  },
  async created() {
    let user_id = await atob(this.$route.params.userid);
    this.fullUrl = await window.location.href
    
    if (await this.$store.getters.isAuthenticated) {
      await this.$store.dispatch("logoutRequest").catch((err) => {}).finally(() => {
        window.location.href = this.fullUrl;
      });	
    }
    await this.login(user_id);
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  img {
    width: 100px;
  }
}
</style>
